const en = {
  "Header": {
    "d1": `Language Settings`,
    "d2": `Switch Language to `,
    "d3": `Cancel`,
    "d4": `Confirm`
  },
  "Bacarat": {
    "d1": `Classic Baccarat`,
    "d2": `1. Game Rules`,
    "d3": `This game uses 8 decks of cards (excluding Jokers) to play, totaling 416 cards;`,
    "d4": `The "Player" and the "Banker" each receive two cards, with the "Player" receiving cards first, followed by the "Banker" (the order of revealing cards is the same). If the first round does not determine a winner, additional cards are drawn according to the "rules," with a maximum of one additional card for each to decide the outcome;`,
    "d5": `If either the Player or the Banker gets 8 or 9 points (a Natural win), the game is settled with the first four cards and no additional cards are drawn；`,
    "d6": `The final result is determined by comparing the unit digit of the total points of the Player and the Banker；`,
    "d7": `2. Drawing Rules`,
    "d8": `After the first round of cards is reveal to the Player and the Banker, determine if additional cards are needed. First, evaluate the Player's hand, then the Banker's hand. Refer to the table below for details:`,
    "d9": `Player Drawing Rules`,
    "d10": `Total Points of Player's Two Cards`,
    "d11": `(Player)`,
    "d12": `Must Draw a Card`,
    "d13": `Can't Draw Card`,
    "d14": `Natural Win, Game Decided`,
    "d15": `Banker Drawing Rules`,
    "d16": `Total Points of Banker's Two Cards`,
    "d18": `(Banker)`,
    "d19": `Must Draw a Card`,
    "d20": `If the Player draws a third card with a value of 8, the Banker must not draw a card`,
    "d21": `If the Player draws a third card with a value of 0, 1, 8, or 9, the Banker must not draw a card`,
    "d22": `If the Player draws a third card with a value of 0, 1, 2, 3, 8, or 9, the Banker must not draw a card`,
    "d23": `If the Player draws a third card with a value of 6 or 7, the Banker must draw a card`,
    "d24": `3. Point Calculation`,
    "d25": `A-9 points are calculated based on their face value, with Ace counting as 1 point.`,
    "d26": `10, J, Q, or K are worth 0 points.`,
    "d27": `Example:`,
    "d28": `4. Game Play Determination`,
    "d29": `4.1. Banker & Player & Tie:`,
    "d30": `If the Banker's points are greater than the Player's, those who bet on the "Banker" win；`,
    "d31": `Banker:`,
    "d32": `Player:`,
    "d33": `If the Player's points are greater than the Banker's, those who bet on the "Player" win；`,
    "d34": `If the Banker's and Player's points are the same, those who bet on a "Tie" win.`,
    "d35": `4.2. Banker Pair & Player Pair:`,
    "d36": `In the current game, if the first two cards of either the Banker or the Player form a pair (same number or letter, suit does not matter)；`,
    "d37": `Banker/Player:`,
    "d38": `If the Banker's first two cards form a pair, it is a Banker Pair. If the Player's first two cards form a pair, it is a Player Pair;`,
    "d39": `When a pair appears, those who bet on "Banker Pair" or "Player Pair" will receive rewards according to the corresponding odds.`,
    "d40": `4.3. Either Pair:`,
    "d41": `Refers to the first two cards of either the Banker or the Player forming a pair (see Banker Pair & Player Pair);`,
    "d42": `Regardless of whether it is a Banker Pair or Player Pair, those who bet on "Either Pair" win.`,
    "d43": `4.4. Perfect Pair:`,
    "d44": `Refers to the first two cards of either the Banker or the Player forming a pair (see Banker Pair & Player Pair) with the same suit;`,
    "d45": `Regardless of whether the Perfect Pair appears for the Banker or the Player, those who bet on "Perfect Pair" win.`,
    "d46": `4.5. Super Pair:`,
    "d47": `Among the first 4 cards, if there are two or more cards with the same face value (regardless of suit).`,
    "d48": `4.6 Dragon 7:`,
    "d49": `The Banker wins with three cards totaling 7 points.`,
    "d50": `4.7 Panda 8:`,
    "d51": `The Player wins with three cards totaling 8 points.`,
    "d52": `4.8. Big Tiger & Small Tiger:`,
    "d53": `The Banker wins with three cards totaling 6 points, called Big Tiger;`,
    "d54": `The Banker wins with two cards totaling 6 points, called Small Tiger.`,
    "d55": `4.9. Tiger Tie:`,
    "d56": `The Banker and Player tie with 6 points each.`,
    "d57": `4.10. Tiger Pair:`,
    "d58": `Based on the results of the first 4 cards of the Banker and Player, if any of the following 3 outcomes are met, different odds are awarded:`,
    "d59": `Both Banker Pair and Player Pair, and they are the same face value pairs;`,
    "d60": `Both Banker Pair and Player Pair;`,
    "d61": `Either Banker Pair or Player Pair;`,
    "d62": `Tie does not refund the bet.`,
    "d63": `4.11. Banker Natural & Player Natural:`,
    "d64": `If the Banker's first two cards total 8 or 9 points and the Banker wins, those who bet on "Banker Natural" win;`,
    "d65": `If the Player's first two cards total 8 or 9 points and the Player wins, those who bet on "Player Natural" win.`,
    "d66": `4.12. Natural:`,
    "d67": `The Banker or Player wins if their first two cards total 8 or 9 points`,
    "d68": `4.13. Dragon Tiger Tie:`,
    "d69": `The Banker wins the Dragon Bet if the point value of the Banker's first card is greater than that of the Player's first card (with 10,J,Q and K valued at 10,11,12 and 13 points respectively);`,
    "d70": `The Player wins the Tiger Bet if the point value of the Player's first card is greater than that of the Banker's first card (with 10,J,Q and K valued at 10,11,12 and 13 points respectively);`,
    "d71": `If the point values of the first cards of both the Banker and the Player are the same (with 10,J,Q and K valued at 10,11,12 and 13 points respectively), it is a Dragon-Tiger Tie.`,
    "d72": `4.14. Super Six:`,
    "d73": `Bets can be placed in both Commission and No Commission modes;`,
    "d74": `If the Banker does not draw a card, has a total point value ending in 6, and this point value is greater than the Player's, the payout is 1:12;`,
    "d75": `If the Banker draws a card, has a total point value ending in 6, and this point value is greater than the Player's, the payout is 1:20.`,
    "d76": `4.15. Banker Dragon Bonus & Player Dragon Bonus:`,
    "d77": `If the Banker wins with a natural, the Banker Dragon Bonus wins;`,
    "d78": `If the Player wins with a natural, the Player Dragon Bonus wins;`,
    "d79": `If the Banker does not have a natural and the difference between the Banker's points and the Player's points (N) is 4 to 9, the Banker Dragon Bonus wins;`,
    "d80": `If the Player does not have a natural and the difference between the Player's points and the Banker's points (N) is 4 to 9, the Player Dragon Bonus wins;`,
    "d81": `Definition of a natural: The result of the first two cards is 8 or 9 points;`,
    "d82": `Definition of a non-natural: All situations other than a natural, see the payout table for details.`,
    "d83": `4.16. Super Tie:`,
    "d84": `In the event of a tie between the Banker and the Player, the tie points include "Tie 0" to "Tie 9," making a total of ten Super Tie points;`,
    "d85": `For example, in the game, if the result of the Banker’s and Player’s cards is both 0, those who bet on "Super Tie 0" win;`,
    "d86": `5.Odds Table`,
    "d87": `In Baccarat, both standard Baccarat and Commission-Free Baccarat are offered. The game rules are the same for both, but there is a difference in the payout method for betting on the Banker:`,
    "d88": `Baccarat`,
    "d89": `Bets Options`,
    "d90": `Odds`,
    "d91": `Player`,
    "d92": `1:1 <br>(Returns the bet amount in case of a tie)`,
    "d93": `Banker`,
    "d94": `Commission 1:0.95 <br>(Returns the bet amount in case of a tie) `,
    "d95": `No Commision 1:1  <br>(Pays half if Banker wins with 6 points; returns the bet amount in case of a tie)`,
    "d96": `Tie`,
    "d97": `Player Pair`,
    "d98": `Banker Pair`,
    "d99": `Super Tie`,
    "d100": `Perfect Pair`,
    "d101": `Either Pair`,
    "d102": `Dragon 7`,
    "d103": `Panda 8`,
    "d104": `Big Tiger`,
    "d105": `Small Tiger`,
    "d106": `Tiger Tie`,
    "d107": `Tiger Pair`,
    "d108": `1:100 <br>Counting Banker Pair, Player Pair, and they are the same face value pair  `,
    "d109": `1:20 <br> Counting Banker Pair, Player Pair  `,
    "d110": `1:4 <br> Counting Either Banker Pair or Player Pair `,
    "d111": `Banker Natural & Player Natural`,
    "d112": `Natural`,
    "d113": `Dragon & Tiger`,
    "d114": `Returns half of the bet in case of a Dragon Tiger Tie`,
    "d115": `Dragon Tiger Tie`,
    "d116": `Super Six`,
    "d117": `1:12 <br>If the Banker wins with two cards totaling 6 points `,
    "d118": `1:20 <br> If the Banker wins with three cards totaling 6 points `,
    "d119": `Dragon Bonus`,
    "d120": `Natural Tie <br>Returns the bet amount`,
    "d121": `Returns the bet amount`,
    "d122": `Wins with a natural`,
    "d123": `Wins with a non-natural totaling 4 points`,
    "d124": `Wins with a non-natural totaling 5 points`,
    "d125": `Wins with a non-natural totaling 6 points`,
    "d126": `Wins with a non-natural totaling 7 points`,
    "d127": `Wins with a non-natural totaling 8 points`,
    "d128": `Wins with a non-natural totaling 9 points`,
    "d129": `Super Tie`,
    "d130": `Super Tie (0) `,
    "d131": `Super Tie (1) `,
    "d132": `Super Tie (2) `,
    "d133": `Super Tie (3) `,
    "d134": `Super Tie (4) `,
    "d135": `Super Tie (5) `,
    "d136": `Super Tie (6) `,
    "d137": `Super Tie (7) `,
    "d138": `Super Tie (8) `,
    "d139": `Super Tie (9) `,
    "d140": `6. Game Features`,
    "d141": `Using the most popular 8 decks (excluding Jokers, totaling 416 cards) gameplay. With unique drawing rules, just like in life, regardless of current circumstances, as long as you keep investing, there's always a chance for a turnaround.`,
    "d142": `7.Precautions`,
    "d143": `Some gameplay options may have restrictions on the number of bets allowed per shoe. For details, please refer to the relevant prompts within the game.`,
    "d144": `Table limit is the minimum and maximum bet limit for the main gameplay on this table.`,
    "d145": `When the dealer reveals the results, occasional situations may arise where the system cannot recognize them. In such cases, the dealer will attempt to re-identify the system until it can recognize them correctly. (In case of malfunction, all related bets/payouts will be canceled).`,
    "d146": `If there is a settlement error, it will be recalculated based on the current video results.`,
    "d147": `If during the game process there is a failure due to issues with the game platform's network or other reasons preventing the completion of the game, the system will cancel all player bets for that round and return the bet amounts.`,
    "d148": `Game Rules`,
    "d149": `Drawing Rules`,
    "d150": `Point Calculation`,
    "d151": `Game Play Determination`,
    "d152": `Odds Table`,
    "d153": `Game Features`,
    "d154": `Precautions`
  },
  "DragonTiger": {
    "d1": `Dragon Tiger`,
    "d2": `1. Game Rules`,
    "d3": `The game uses 8 decks of cards (excluding Jokers), totaling 416 cards;`,
    "d4": `Only two hands are dealt, first to the Dragon, then to the Tiger, each receiving 1 card, no drawing of additional cards required；`,
    "d5": `After the deal, the hands are compared based on the point total of each side to determine the outcome.`,
    "d6": `2. Point Calculation`,
    "d7": `The face value of A is 1；`,
    "d8": `The face value of cards 2-10 corresponds to their face value, so counts as 2-10；`,
    "d9": `J, Q, K counts as 11, 12, 13, respectively.`,
    "d10": `3. Game Play Determination`,
    "d11": `In comparing Dragon and Tiger, the larger hand wins; if Dragon is larger, Dragon wins, if Tiger is larger, Tiger wins；`,
    "d12": `If Dragon and Tiger have the same point total, it's a tie.`,
    "d13": `4.Odds Table`,
    "d14": `Bets Options`,
    "d15": `Odds`,
    "d16": `Dragon`,
    "d18": `1:1`,
    "d19": `Half the bet returned in the event of a tie.`,
    "d20": `Tiger`,
    "d21": `1:1`,
    "d22": `Half the bet returned in the event of a tie.`,
    "d23": `Tie`,
    "d24": `1:8`,
    "d25": `5. Game Features`,
    "d26": `Compared to Baccarat, no need to draw cards, single card showdown. The rules are simple, easy to get started, suitable for all ages to quickly experience the joy of the game.`,
    "d27": `6. Precautions`,
    "d28": `Some gameplay options may have restrictions on the number of rounds you can bet on in this shoe. For more details, please refer to the relevant prompts in the game.`,
    "d29": `Table limit is the minimum and maximum bet limit for the main gameplay on this table.`,
    "d30": `When the dealer reveals the results, occasional situations may arise where the system cannot recognize them. In such cases, the dealer will attempt to re-identify the system until it can recognize them correctly. (In case of malfunction, all related bets/payouts will be canceled).`,
    "d31": `In case of settlement errors, the settlement will be recalculated based on the video results of the current period.`,
    "d32": `If any network or platform issues during the game prevent the completion of the round, the system will cancel all player bets for that round and refund the bet amount.`,
    "d33": `Game Rules`,
    "d34": `Point Calculation`,
    "d35": `Game Play Determination`,
    "d36": `Odds Table`,
    "d37": `Game Features`,
    "d38": `Precautions`
  },
  "Bulls": {
    "d1": `BullBull`,
    "d2": `1. Game Rules`,
    "d3": `A deck of 52 cards (excluding Jokers);`,
    "d4": `The dealing order is Banker → Player 1 → Player 2 → Player 3 in a loop, but it is determined by the first card who the first hand will be dealt to;`,
    "d5": `The three players compete with the Banker, comparing card types first. If the card types are the same, then they compare the value of the highest card. If the face value is the same, they compare the suits.`,
    "d6": `2. Point Calculation`,
    "d7": `Ace counts as 1; cards 10, J, Q, K count as 10 points; cards 2-9 count as their face value;`,
    "d8": `If the sum of three cards in the hand adds up to a multiple of 10, it's considered 'having a Bull.' Otherwise, it's 'no Bull;`,
    "d9": `In the card game, if there's a (Bull), the sum of the remaining two cards' units digit determines the value. If the sum's unit digit is 1-9, it corresponds to its "Bull". If it is 0, it is called "BullBull".`,
    "d10": `If all five cards are J/Q/K, the hand is classified as "Five Face".`,
    "d11": `3.Game Play Determination`,
    "d12": `In the game of "BullBull," there is the Banker, Player 1, Player 2, and Player 3 with their hands;`,
    "d13": `The betting layout is divided into three sections: Player 1, Player 2, and Player 3. Each section includes options for Banker Even,Banker Double,Banker Super,Player Even, Player Double and Player Super;`,
    "d14": `After the cards are revealed, the hands of the Banker and the three Players are compared. If the Banker's hand is higher, bets on the Banker win. If a Player's hand is higher, bets on the Player win;`,
    "d15": `Hand Rankings: Five Face> BullBull > Bull 9 > Bull 8 > Bull 7 > Bull 6 > Bull 5 > Bull 4 > Bull 3 > Bull 2 > Bull 1 > No Bull.`,
    "d16": `Rankings: K > Q > J > 10 > 9 > 8 > 7 > 6 > 5 > 4 > 3 > 2 > A`,
    "d18": `Suit Rankings: Spades > Hearts > Clubs > Diamonds;`,
    "d19": `Betting even payout results in an equal win or loss. Betting double payout results in a multiplied win or loss.`,
    "d20": `4.Odds Table`,
    "d21": `The specific payout multiplier depends on the Winning Hand. Betting even payout results in an equal win or loss, while betting double payout results in a multiplied win or loss.`,
    "d22": `Player's Winning Odds`,
    "d23": `Winning Hand`,
    "d24": `Even`,
    "d25": `Double`,
    "d26": `Five Face`,
    "d27": `BullBull`,
    "d28": `Bull`,
    "d29": `No Bull`,
    "d30": `Player's Losing Odds`,
    "d31": `Winning Hand`,
    "d32": `Even`,
    "d33": `Double`,
    "d34": `Five Face`,
    "d35": `BullBull`,
    "d36": `Bull 7 - Bull 9`,
    "d37": `No Bull - Bull 6`,
    "d38": `Example 1 of "double payout" betting and payout:`,
    "d39": `Betting Object`,
    "d40": `One Player`,
    "d41": `Gameplay`,
    "d42": `Player Double `,
    "d43": `Betting Amount`,
    "d44": `100, actually deducted 500, will be displayed in records as "100 (pre-deducted 400)"`,
    "d45": `Comparison Result`,
    "d46": `Banker's hand: Bull 8 | One Player's hand: Bull 5 `,
    "d47": `Payout`,
    "d48": `Returning 300 (winning hand Bull 8, corresponding to player's loss payout of 2, player loses 100 x 2 = 200)`,
    "d49": `Example 2 of "double payout" betting and payout:`,
    "d50": `Betting Object`,
    "d51": `One Player`,
    "d52": `Gameplay`,
    "d53": `Banker Double `,
    "d54": `Betting Amount`,
    "d55": `100, actually deducted 500, will be displayed in records as "100 (pre-deducted 400)"`,
    "d56": `Comparison Result`,
    "d57": `Banker's hand: Bull 8 | One Player's hand: Bull 5 `,
    "d58": `Payout`,
    "d59": `690 (Return of 500 + 100 x player's win payout of 1.90 for the winning hand Bull 8 = 690)`,
    "d60": `5. Game Features`,
    "d61": `A card game popular in the Hubei and Hunan regions. Players can freely bet on the Banker or Player. The double-up feature will result in various odds based on the Winning Hand, providing an exciting and thrilling experience!`,
    "d62": `6. Precautions`,
    "d63": `Some game features may have restrictions on the number of rounds allowed for betting in a particular shoe. Please refer to the in-game prompts for more details.`,
    "d64": `Table limit is the minimum and maximum bet limit for the main gameplay on this table.`,
    "d65": `When the dealer reveals the results, occasional situations may arise where the system cannot recognize them. In such cases, the dealer will attempt to re-identify the system until it can recognize them correctly. (In case of malfunction, all related bets/payouts will be canceled).`,
    "d66": `In case of any settlement errors, the settlement will be re-calculated based on the video results of that period.`,
    "d67": `If the game cannot be completed normally due to platform, network, or other malfunctions during the game, the system will cancel all player bets for that round and return the bet amounts.`,
    "d68": `Game Rules`,
    "d69": `Point Calculation`,
    "d70": `Game Play Determination`,
    "d71": `Odds Table`,
    "d72": `Game Features`,
    "d73": `Precautions`,
    "d74": `Super`
  },
  "SanGong": {
    "d1": `Three Face`,
    "d2": `1. Game Rules`,
    "d3": `A deck of 52 cards (excluding Jokers);`,
    "d4": `The dealing sequence is Banker → Player 1 → Player 2 → Player 3 in a loop, but the starting player for each round is determined by the "head card";`,
    "d5": `The hands of the three Players are compared with the Banker's hand. If a Player has a higher hand, that Player wins; if the Banker has a higher hand, the Player loses.`,
    "d6": `2. Hand Rankings`,
    "d7": `In the game, face cards J,Q,K are considered as community cards.`,
    "d8": `Gameplay`,
    "d9": `Instructions (Hand Rankings from highest to lowest, listed from top to bottom)`,
    "d10": `Three Face`,
    "d11": `J,Q,K are 'Face'; a hand consisting of all three 'Face' cards is called 'Three Face'`,
    "d12": `Double Face 9`,
    "d13": `Two Face cards and the other card is 9 points`,
    "d14": `Single Face 9`,
    "d15": `One face card, total of the other two cards equals 9, with the ones digit being 9`,
    "d16": `9 Points`,
    "d18": `The total points of the 3 cards is 9, with the ones digit being 9`,
    "d19": `Double Face 8`,
    "d20": `Two Face cards and the other card is 8 points`,
    "d21": `Single Face 8`,
    "d22": `One face card, total of the other two cards equals 8, with the ones digit being 8`,
    "d23": `8 Points`,
    "d24": `The total points of the 3 cards is 8, with the ones digit being 8`,
    "d25": `Double Face 7`,
    "d26": `Two Face cards and the other card is 7 points`,
    "d27": `Single Face 7`,
    "d28": `One face card, total of the other two cards equals 7, with the ones digit being 7`,
    "d29": `7 Points`,
    "d30": `The total points of the 3 cards is 7, with the ones digit being 7`,
    "d31": `Double Face 6`,
    "d32": `Two Face cards and the other card is 6 points`,
    "d33": `Single Face 6`,
    "d34": `One face card, total of the other two cards equals 6, with the ones digit being 6`,
    "d35": `6 Points`,
    "d36": `The total points of the 3 cards is 6, with the ones digit being 6`,
    "d37": `Double Face 5`,
    "d38": `Two Face cards and the other card is 5 points`,
    "d39": `Single Face 5`,
    "d40": `One face card, total of the other two cards equals 5, with the ones digit being 5`,
    "d41": `5 Points`,
    "d42": `The total points of the 3 cards is 5, with the ones digit being 5`,
    "d43": `Double Face 4`,
    "d44": `Two Face cards and the other card is 4 points`,
    "d45": `Single Face 4`,
    "d46": `One face card, total of the other two cards equals 4, with the ones digit being 4`,
    "d47": `4 Points`,
    "d48": `The total points of the 3 cards is 4, with the ones digit being 4`,
    "d49": `Double Face 3`,
    "d50": `Two Face cards and the other card is 3 points`,
    "d51": `Single Face 3`,
    "d52": `One face card, total of the other two cards equals 3, with the ones digit being 3`,
    "d53": `3 Points`,
    "d54": `The total points of the 3 cards is 3, with the ones digit being 3`,
    "d55": `Double Face 2`,
    "d56": `Two Face cards and the other card is 2 points`,
    "d57": `Single Face 2`,
    "d58": `One face card, total of the other two cards equals 2, with the ones digit being 2`,
    "d59": `2 Points`,
    "d60": `The total points of the 3 cards is 2, with the ones digit being 2`,
    "d61": `Double Face 1`,
    "d62": `Two Face cards and the other card is 1 point`,
    "d63": `Single Face 1`,
    "d64": `One face card, total of the other two cards equals 1, with the ones digit being 1`,
    "d65": `1 Point`,
    "d66": `The total points of the 3 cards is 1, with the ones digit being 0`,
    "d67": `Double Face 0`,
    "d68": `Two Face cards and the other card is 0 points`,
    "d69": `Single Face 0`,
    "d70": `One face card, total of the other two cards equals 0, with the ones digit being 0`,
    "d71": `0 Point`,
    "d72": `The total points of the 3 cards is 0, with the ones digit being 0`,
    "d73": `3.Pairs or higher hand rankings`,
    "d74": `Straight Flush:`,
    "d75": `3 cards are consecutive and same suit,including A,2,3 and Q,K,A;`,
    "d76": `Three of a Kind:`,
    "d77": `Three cards with the same rank;`,
    "d78": `Straight:`,
    "d79": `Three cards are consecutive and in different suit，including A,2,3 and Q,K,A;`,
    "d80": `Flush:`,
    "d81": `Three hand cards are of the same suit, but not consecutive in rank`,
    "d82": `Pair:`,
    "d83": `Three hand cards with 2 pairs cards same value,considered as pair.`,
    "d84": `3.Game Play Determination`,
    "三公中有庄，闲1，闲2，闲3四家牌；": `In the game of "Three Face," there is the Banker, Player 1, Player 2, and Player 3 with their hands;`,
    "下注盘则分为闲1，闲2，闲3三门，每门包含庄平倍，庄翻倍，庄超倍，闲平倍，闲翻倍，闲超倍；": `The betting layout is divided into three sections: Player 1, Player 2, and Player 3. Each section includes options for Banker Even , Banker Double , Banker Super, Player Even , Player Double,Player Super;`,
    "开牌后，以庄和三家闲比大小。庄大押庄赢。闲大押闲赢；": `After the cards are revealed, the hands of the Banker and the three Players are compared. If the Banker's hand is higher, bets on the Banker win. If a Player's hand is higher, bets on the Player win;`,
    "大小：K＞Q＞J＞10＞9＞8＞7＞6＞5＞4＞3＞2＞A；": `Rankings: K > Q > J > 10 > 9 > 8 > 7 > 6 > 5 > 4 > 3 > 2 > A`,
    "花色：黑桃＞红心＞梅花＞方块；": `Suit Rankings: Spades > Hearts > Clubs > Diamonds;`,
    "押平倍，输赢平倍。押翻倍，则输赢多倍。押超倍，则输赢多倍。": `Betting even payout results in an equal win or loss. Betting double payout results in a multiplied win or loss.Betting super payout results even more win or lose.`,
    "d91": `4.Odds Table`,
    "d92": `The odds are divided into banker-player win/loss odds, and special hand type odds.`,
    "d93": `Cards Result`,
    "d94": `Odds`,
    "d95": `Player (win)`,
    "d96": `1:1`,
    "d97": `Player (lose)`,
    "d98": `1:1`,
    "d99": `Tie`,
    "d100": `Three Face`,
    "d101": `Hand Rankings`,
    "d102": `Odds`,
    "d103": `Straight Flush`,
    "d104": `Three of a Kind`,
    "d105": `Straight`,
    "d106": `Flush`,
    "d107": `Pair`,
    "d108": `If a hand simultaneously meets multiple outcomes, the outcome with the higher odds prevails; `,
    "d109": `Example ♠️2 ♠️3 ♠️4 can form a straight flush and a straight, but since the straight flush has higher odds, the payout will be based on the straight flush.`,
    "d110": `There is no commission on bets, but there are special odds in the following two situations: `,
    "d111": `For winning bets, if the player wins with any combination totaling 6 points, the payout is 1 to 0.5;`,
    "d112": `For losing bets, if the banker wins with any combination totaling 6 points, the payout is 1 to 0.5.`,
    "d113": `5.Game Features`,
    "d114": `3 Face is a traditional folk game with simple betting, lively atmosphere, no need for complex operations or calculations. It relies on luck and is quite popular in emerging game genres.`,
    "d115": `6.Precautions`,
    "d116": `Some gameplay may have restrictions on the number of rounds that can be bet in this shoe. For details, please refer to the relevant prompts in the game.`,
    "d117": `Table limit is the minimum and maximum bet limit for the main gameplay on this table.`,
    "d118": `When the dealer reveals the results, occasional situations may arise where the system cannot recognize them. In such cases, the dealer will attempt to re-identify the system until it can recognize them correctly. (In case of malfunction, all related bets/payouts will be canceled).`,
    "d119": `In the event of settlement errors, the settlement will be recalculated based on the video results of the current period.`,
    "d120": `If network or platform malfunctions during the game process prevent the completion of a hand, the system will cancel all player bets for that entire hand and refund the bet amount placed by the players.`,
    "d121": `Game Rules`,
    "d122": `Hand Rankings`,
    "d123": `Pairs or higher hand rankings`,
    "d124": `Game Play Determination`,
    "d125": `Odds Table`,
    "d126": `Game Features`,
    "d127": `Precautions`,
    "玩家赢赔率": `Player's Winning Odds`,
    "玩家输赔率": `Player's Losing Odds`,
    "胜方牌型": `Winning Hand`,
    "平倍": `Even`,
    "翻倍": `Double`,
    "超倍": `Super`,
    "三条": `Three of a Kind`,
    "3条3": `Three cards of three`,
    "点": `Point`
  },
  "GoldenFlower": {
    "龙凤炸金花": `D&P win three cards`,
    "游戏规则": `Game Rules`,
    "使用一副牌游戏（去大小王）进行游戏；": `The games is using a standard deck of cards (excluding Jokers) `,
    "先比较牌型大小，若牌型一致再按从大到小的顺序依次比较牌面大小（不看花色），决定胜负。若龙、凤的三张牌大小一致，则为和局，退回玩家在本局下注龙、凤的筹码。": `First compare the ranks of the hands. If the ranks are the same, then compare the individual card values in descending order (regardless of suits) to determine the winner. If the three cards of Dragon and Phoenix are of the same value, it's considered a tie, and all bets placed on Dragon and Phoenix in this round will be returned.`,
    "玩法判定": `Game Play Determination`,
    "牌型": `Hand Type`,
    "豹子杀手：": `2-3-5 Killer: `,
    "三张非同花的2，3，5；": `Three non-suited 2, 3, 5;`,
    "豹子：": `Three of a kind: `,
    "三张点数相同的牌；": `Three cards of the same rank;`,
    "同花顺：": `Straight Flush: `,
    "花色相同的顺子牌；": `Straight cards of the same suit;`,
    "同花：": `Flush:`,
    "花色相同的任意三张牌；": `Three cards of any rank with the same suit;`,
    "顺子：": `Straight: `,
    "三张连续的牌；": `Three consecutive cards;`,
    "对子：": `Pair: `,
    "两张点数相同的牌；": `Two cards of the same rank;`,
    "散牌：": `High Card:`,
    "无法组成任何类型的牌；": `Unable to form any type of hand;`,
    "牌型大小": `Hand Rankings`,
    "豹子＞同花顺＞同花＞顺子＞对子＞散牌；": `Three of a kind > Straight Flush > Flush > Straight > Pair > High Card;`,
    "豹子杀手＞豹子；": `2-3-5 Killer > Three of a kind;`,
    "当豹子牌和豹子杀手牌同时存在时，豹子杀手牌比豹子牌大；": `When a 2-3-5 Killer hand and a Three of a kind hand are present simultaneously, the 2-3-5 Killer hand is stronger than the Three of a kind hand;`,
    "散牌＞豹子杀手；": `High Card > 2-3-5 Killer;`,
    "如不存在豹子牌，豹子杀手是最小的散牌牌型。": `If there are no Three of a kind hands, 2-3-5 Killer is the lowest High Card hand.`,
    "对子大小": `Pair Rankings`,
    "如果两家都是对子牌，即比对对子点数大小，对A＞对K＞对Q＞对J＞对10＞对9＞对8＞对7＞对6＞对5＞对4＞对3＞对2；": `>If both players have a Pair hand, compare the Pair ranks; Pair A > Pair K > Pair Q > Pair J > Pair 10 > Pair 9 > Pair 8 > Pair 7 > Pair 6 > Pair 5 > Pair 4 > Pair 3 > Pair 2;`,
    "两对对子的点数相同，再比单牌点数大小。": `>If the ranks of two pairs are the same, then compare the ranks of the individual cards.`,
    "豹子、同花、散牌牌型": `Three of a kind, Flush, High Card Hands`,
    "比最大那张牌的点数，如最大的那张点数相同，则比第二大牌的点数，以此类推；A>K>Q>J>10>9>8>7>6>5>4>3>2，花色不分大小。": `Compare the point value of the highest card; if they are the same, compare the point value of the second-highest card, and so on;
In terms of point value: A > K > Q > J > 10 > 9 > 8 > 7 > 6 > 5 > 4 > 3 > 2. Suits are not ranked.`,
    "同花顺，顺子": `Straight Flush and Straight:`,
    "按照顺序比点数：AKQ>KQJ>......>432>32A": `Compare the card ranks in sequence: AKQ > KQJ > ... > 432 > 32A`,
    "注意：只有在同花顺和顺子牌型中，A可作为最大或最小的牌，其余牌型中A为最大的单牌": `Note: Only in the Straight Flush and Straight hand types can the Ace act as either the highest or lowest card. In all other hand types, the Ace is considered the highest single card.`,
    "赔率表": `Odds Table`,
    "投注项目": `Bets Options`, 
    "规则": `Rules`,
    "赔率": `Odds`,
    "龙": `Dragon`,
    "龙胜出": `Dragon Wins`,
    "凤": `Phoenix`,
    "凤胜出": `Phoenix Wins`,
    "对8以上": `Pair 8 Above`,
    "龙或凤以对8以上的牌型胜出": `Dragon or Phoenix wins with pair 8 above in hand`,
    "顺子": `Straight`,
    "龙或凤以顺子的牌型胜出": `Dragon or Phoenix wins with a Straight in hand`,
    "同花": `Flush`,
    "龙或凤以同花的牌型胜出": `Dragon or Phoenix wins with a Flush in hand`,
    "同花顺": `Straight Flush`,
    "龙或凤以同花顺的牌型胜出": `Dragon or Phoenix wins with a Straight Flush in hand`,
    "豹子": `Three of a kind`,
    "龙或凤以豹子的牌型胜出": `Dragon or Phoenix wins with a Three of a Kind in hand`,
    "游戏特点": `Game Features`,
    "炸金花，也被称为赢三张。是一种广泛流传的民间游戏。最大特色除常规押注龙，凤。也可对赢家牌型进行投注。甚至任何对8以上牌型都为赢的玩法。可谓由简至繁，满足各阶层玩家。": `Dragon Pheonix Win Three Cards, also known as Win Three Cards, is a widely popular folk game. Its main feature, apart from the usual betting on Dragon and Phoenix, is the ability to bet on the Winning Hand. Players can even bet on any hand type of 8 or higher as the winning hand. Starting from simple to complex, it caters to players of all levels.`,
    "注意事项": `Precautions`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Some gameplay may have restrictions on the number of rounds that can be bet in this shoe. For details, please refer to the relevant prompts in the game.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Table limit is the minimum and maximum bet limit for the main gameplay on this table.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单": `When the dealer reveals the results, occasional situations may arise where the system cannot recognize them. In such cases, the dealer will attempt to re-identify the system until it can recognize them correctly. (In case of malfunction, all related bets/payouts will be canceled).`,
    "如遇结算错误，按照当期视频结果重新结算。": `In the event of settlement errors, the settlement will be recalculated based on the video results of the current period.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `If network or platform malfunctions during the game process prevent the completion of a hand, the system will cancel all player bets for that entire hand and refund the bet amount placed by the players.`
  },
  "TexasHoldem": {
    "德州扑克": `Hold'em`,
    "游戏规则": `Game Rules`,
    "采用1副牌（去大小王），共计52张；": `Use one deck (excluding Jokers), totaling 52 cards;`,
    "首先发出5张公共牌，然后发给闲家2张手牌，庄家2张手牌；": `First, deal 5 community cards, then deal 2 hole cards to the player and 2 hole cards to the banker;`,
    "投注倒计时结束后，荷官会首先翻开闲家2张手牌和前3张公共牌；": `After the betting countdown ends, the dealer will first reveal the player's two hole cards and the first three community cards;`,
    "然后进入跟注阶段，闲家需要决定是否跟注；如果选择跟注，则需要在跟注区域投注相当于底注2倍的筹码。如果不跟注，则放弃本局，输掉底注。": `Then, the game enters the calling phase, where the player must decide whether to call the bet. If they choose to call, they need to place a bet equal to twice the initial bet in the calling area. If they choose not to call, they forfeit the round and lose their initial bet.`,
    "跟注环节结束后，荷官翻开后2张公共牌；": `After the calling phase ends, the dealer will reveal the last two community cards.`,
    "玩家通过自己的2张手牌和5张公共牌组成最大的5张牌，跟庄家比大小。": `Players use their two hole cards and the five community cards to form the best five-card hand, and then compare it with the banker's hand to determine who has the higher hand.`,
    "AA边注玩法": `AA Side Bet Rules`,
    "AA边注是可选的。可用于投注最先发的5张牌（玩家的两张牌和前三张公共牌）有没有一对A或更好的牌。": `The AA side bet is optional. It can be used to bet whether the first five cards dealt (the player's two cards and the first three community cards) contain a pair of Aces or a better hand.`,
    "玩法判定": `Gameplay Play Determination`,
    "牌型": `Hand Rankings`,
    "皇家同花顺": `Royal Flush`,
    "皇家同花顺是指包括A、K、Q、J、10这样大牌的同花顺。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `A Royal Flush consists of a sequence including the A,K,Q, J, and 10 of the same suit. In the event of a tie, players retrieve their initial bets (both the ante and the call bets), and the game result is a draw.`,
    "同花顺": `Straight Flush`,
    "同花顺是指五张连续数值的同花牌，比如：9、8、7、6、5，而且全部是红心。两个同花顺相比，大牌面的胜出。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `A Straight Flush consists of five cards of sequential rank, all of the same suit, such as 9, 8, 7, 6, 5 of hearts. When comparing two straight flushes, the one with the higher top card wins. In the event of a tie, players retrieve their initial bets (both the ante and the call bets), and the game result is a draw.`,
    "注：同花A、2、3、4、5为最小的同花顺；同花J、Q、K、A、2/Q、K、A、2、3/K、1、2、3、4不为同花顺。": `Note: A suited A, 2, 3, 4, 5 is the lowest Straight Flush; however, suited J, Q, K, A, 2 or Q, K, A, 2, 3 or K, 1, 2, 3, 4 do not count as Straight Flush.`,
    "四条": `Four of a Kind`,
    "四条是指一手牌中包含四种花色同一数值的牌，再加上一张其他牌。比如说，您拿到了四种花色的A，那就是四条。大牌值的四条大于小牌值的四条。": `Four of a Kind refers to a hand that contains four cards of the same rank, each of a different suit, plus one other card. For example, if you have the Ace of each suit, that constitutes a Four of a Kind. A higher ranked Four of a Kind beats a lower ranked one.`,
    "葫芦": `Full House`,
    "葫芦是指一手牌中有三种花色的同值牌，还有两种花色的另一同值牌，例如三张K和两张6。在两手葫芦牌中，三张同值牌面较大的一家胜出。": `A Full House consists of three cards of the same rank of any suit, plus two cards of another rank, such as three Kings and two sixes. In a comparison of two Full Houses, the hand with the higher ranking set of three cards wins.`,
    "同花": `Flush`,
    "同花是指一手牌中的所有牌都是同一花色，但不是连续的，例如五张梅花。在两手同花中，根据每手牌中最大牌面的大小决定输赢。如果两手牌中最大牌面大小相同，那么以第二大牌面的大小决定输赢，以此类推直到分出输赢。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `A Flush consists of all cards in the hand being of the same suit, though not in sequence, such as five clubs. In a comparison between two Flushes, the winner is determined by the highest card in each hand. If the highest cards are the same, the second highest cards are compared, and so on, until a winner is determined. In the event of a tie, players retrieve their initial bets (both the ante and the call bets), and the game result is a draw.`,
    "顺子": `Straight`,
    "顺子是指一手牌中五张牌都是连续的，但不是同种花色，例如非同花9、8、7、6、5。两手顺子相比，大牌面的一手胜出。如果两手顺子牌面值相同，花色不作为决定输赢的条件；如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `A Straight consists of five consecutive cards that are not all of the same suit, such as a non-suited 9, 8, 7, 6, 5. When comparing two Straights, the hand with the higher top card wins. If two Straights have the same highest card, suit does not influence the outcome; in the event of a tie, players retrieve their initial bets (both the ante and the call bets), and the game result is a draw. `,
    "注：A、2、3、4、5为最小的顺子；J、Q、K、A、2/Q、K、A、2、3/K、1、2、3、4不为顺子。": `Note: A, 2, 3, 4, 5 is the lowest possible Straight; J, Q, K, A, 2 or Q, K, A, 2, 3 or K, 1, 2, 3, 4 do not count as Straights.`,
    "三条": `Three of a Kind`,
    "三条是指一手牌中包含三种花色的同值牌，还有两张非同值牌。比如说，如果玩家拿到了三条K，那就称之为三条。大牌面的三条胜出小牌面的三条。": `Three of a Kind refers to a hand containing three cards of the same rank, each of a different suit, along with two other non-matching cards. For example, if a player has three Kings, it is called Three of a Kind. A higher-ranked Three of a Kind beats a lower-ranked Three of a Kind.`,
    "两对": `Two Pair`,
    "两对以下": `Below Two Pair`,
    "两对是指一手牌中包含两张同值牌，还有两张另一牌值的同值牌（牌面相等，但是与第一个对子牌面不相等），还有一张其他牌面的牌。一手牌中包含两张A和两张K就是两对。对于两手两对牌，比较各手牌中牌面较大的对子，大牌面的一手胜出。如果两手牌中较大牌面对子大小相同，则比较第二个对子。如果两个对子大小均相同看，则根据另外一张单牌决定输赢。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `Two Pair refers to a hand containing two cards of one rank and two cards of another rank (equal in rank but different from the first pair), plus one other card. A hand with two Aces and two Kings is an example of Two Pair. In comparing two Two Pair hands, the pair with the higher value in each hand is compared first, and the hand with the higher pair wins. If the higher pairs are the same, then the lower pairs are compared. If both pairs are identical, the outcome is decided by the remaining single card. In the event of a tie, players retrieve their initial bets (both the ante and the call bets), and the game result is a draw.`,
    "一对": `Pair`,
    "对子是指一手牌中包含两张同值牌（例如两张K），再加上三张其它非同值牌。牌值较大的对子胜过较小的对子。如果两手牌对子的牌面大小相同，则降序比较其它三张牌。如果出现平局，玩家拿回初始赌注（底注和跟注），游戏结果为平局。": `A Pair refers to a hand containing two cards of the same rank (for example, two Kings), plus three other cards that do not match each other in rank. A higher ranked pair beats a lower ranked pair. If the pairs are of equal rank in two hands, the remaining three cards are compared in descending order. In the event of a tie, players retrieve their initial bets (both the ante and the call bets), and the game result is a draw.`,
    "高牌": `High Card`,
    "高牌是指不属于上述任何一种牌型的一手牌。不属于任何牌型。": `High Card refers to a hand that does not fit any of the previously mentioned categories. It belongs to no specific hand type.`,
    "牌型大小": `Hand Rankings`,
    "皇家同花顺＞同花顺＞四条＞葫芦＞同花＞顺子＞三条＞两对＞一对＞高牌": `Royal Flush > Straight Flush > Four of a Kind > Full House > Flush > Straight > Three of a Kind > Two Pair > One Pair > High Card`,
    "庄家最后的牌型必须为对4或以上，才有资格与闲家牌型比对；否则视为闲家赢。": `The dealer's final hand must be a Pair of Fours or better to qualify for comparison with the player's hand; otherwise, the player is considered the winner.`,
    "输赢判定和赔率表": `Determination of Wins and Losses and Payout Table`,
    "底注、跟注玩法赔率（不含本金）如下：": `The payout ratios for ante and call bets (excluding the principal) are as follows:`,
    "输赢": `Wins and Losses`,
    "底注": `Ante`,
    "跟注": `Call Bet`,
    "庄家没有资格(对4或以下),玩家胜": `Dealer no qualifies (with pair 4 or below), player wins`,
    "赔付表": `Payout Table`,
    "返回本金": `Return of Principal`,
    "庄家有资格(对4或以上),玩家胜": `Dealer qualifies (with pair 4 or above), player wins`,
    "庄家有资格(对4或以上),平局": `Dealer qualifies (with pair 4 or above), tie`,
    "玩家不跟注(弃牌)": `Player folds`,
    "输": `Lose`,
    "庄家有资格(对4或以上),庄家胜": `Dealer qualifies (with pair 4 or above), dealer wins`,
    "底注玩法赔率（不含本金）如下：": `Ante (excluding principal) are as follows:`,
    "手牌": `Hand`,
    "赔率": `Odds`,
    "AA边注玩法赔率（不含本金）如下：": `AA side bet (excluding principal) are as follows:`,
    "注意事项": `Precautions`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Certain gameplay may be subject to betting limits on specific rounds in the current shoe; for details, please refer to the relevant prompts within the game.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Table limit is the minimum and maximum bet limit for the main gameplay on this table.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `When the dealer reveals the results, occasional situations may arise where the system cannot recognize them. In such cases, the dealer will attempt to re-identify the system until it can recognize them correctly. (In case of malfunction, all related bets/payouts will be canceled).`,
    "如遇结算错误，按照当期视频结果重新结算。": `In case of a settlement error, the settlement will be recalculated based on the current video results.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `If a game round cannot be completed normally due to network or other malfunctions of the gaming platform, the system will cancel the entire round of bets and refund the bet principal to the players.`
  },
  "SicBo": {
    "骰宝": `Sic Bo`,
    "游戏规则": `Game Rules`,
    "荷官启动开关，机器产生3次或3次以上震动，透明骰蛊中三颗6面骰子将在震动作用下弹跳；": `The dealer activates the switch, causing the machine to vibrate three or more times, and the three six-sided dice in the transparent dice shaker will bounce under the effect of the vibration;`,
    "当停止震动，骰子落下时，取其上面显现的点数计数；": `When the vibration stops and the dice settle, the numbers displayed on the top faces of the dice are counted;`,
    "和三颗骰子所表现点数，组合，或和值相符合的玩法胜出；": `Bets that match the numbers, combinations, or sum of the numbers shown on the three dice win;`,
    "如震动停止后，三颗骰子有任意颗底面没有完全接触骰蛊最底面，或出现重叠状态，或震动次数少于3次，将会再次触发震动重新获取结果。": `After the vibration stops, any of the three dice are not fully in contact with the bottom of the dice shaker, are overlapping, or if the number of vibrations is less than three, the vibration will be triggered again to obtain a new result.`,
    "玩法判定": `Game Play Determination`,
    "大/小": `Big/Small`,
    "三颗骰子总点数11-17为大，4-10为小；": `A total of 11-17 points on the three dice is considered 'Big', and 4-10 points is considered 'Small';`,
    "出现围骰，庄家通吃，大/小均输。": `If a triple appears, the house sweeps (wins all), and both 'Big' and 'Small' bets lose.`,
    "单/双": `Odd/Even`,
    "三颗骰子和值，分为单双两种情况；": `The sum of the three dice can be categorized into two types: odd or even;`,
    "总点数为5，7，9，11，13，15，17为单；": `Total points of 5, 7, 9, 11, 13, 15, and 17 are considered Odd;`,
    "总点数为4，6，8，10，12，14，16为双；": `Total points of 4, 6, 8, 10, 12, 14, and 16 are considered Even;`,
    "出现围骰，庄家通吃，单/双均输。": `If a triple appears, the house wins all, and both 'Odd' and 'Even' bets lose.`,
    "单点": `Single Point`,
    "根据所选择点数相符的骰子出现次数判断；": `Determined by the number of times the chosen number appears on the dice;`,
    "有一个骰子符合，则为单骰；": `If one dice matches, it is considered a 'On Single';`,
    "有两个骰子符合，则为双骰；": `If two dice match, it is considered a 'On Double';`,
    "有三个骰子符合，则为三骰。": `If three dice match, it is considered a 'On Triple'.`,
    "和值": `Sum Total`,
    "三个骰子的点数之和为和值；": `The sum of the three dice is called the total;`,
    "和值范围为4-17点。不同和值具有不同赔率（详见赔率）。": `The range for the total is 4-17 points. Different totals have different odds (see odds for details).`,
    "牌九式": `Tile Game`,
    "三颗骰子中，任意两颗骰子满足特定组合条件；": `Among the three dice, any two that meet specific combination conditions;`,
    "总共15种开出的三颗骰子任意两个满足即可胜出。": `Out of 15 possible outcomes, any two of the three dice that meet the conditions will result in a win.`,
    "对子": `Pair`,
    "三颗骰子中，任意两颗骰子点数相同即为对子；": `In the three dice, any two dice with the same number of points constitute a pair;`,
    "在6种对子玩法中押中，即可胜出。": `Winning occurs if the bet is placed on one of the six possible pairs.`,
    "全围": `Any Triples`,
    "三颗骰子点数相同即全围；": `All three dice showing the same number is called 'All Triples;`,
    "包含6种围骰组合，出现任意一种即可胜出。": `Includes six types of triple combinations, winning occurs if any one appears.`,
    "围骰": `Triples`,
    "投注特定的一种围骰，即三颗骰子点数完全一样。满足条件即可胜出。": `Betting on a specific triple, where all three dice have the same number. Winning occurs if this condition is met.`,
    "赔率表": `Odds Table`,
    "玩法赔率": `Gameplay Odds`,
    "投注项目": `Bets Options`,
    "赔率": `Odds`,
    "大": `Big`,
    "小": `Small`,
    "单骰": `On Single`,
    "双骰": `On Double `,
    "三骰": `On Triple`,
    "对子（双骰、长牌）": `Pairs (Double Dice, Long Card)`,
    "牌九式（骨牌、短牌）": `Tile Game (Domino, Short Card)`,
    "点数和为4或17": `Total  is 4 or 17`,
    "点数和为5或16": `Total  is 5 or 16`,
    "点数和为6或15": `Total  is 6 or 15`,
    "点数和为7或14": `Total  is 7 or 14`,
    "点数和为8或13": `Total  is 8 or 13`,
    "点数和为9、10、11或12": `Total  is 9, 10, 11 or 12`,
    "单": `Odd`,
    "双": `Even`,
    "游戏特点": `Game Features`,
    "是古老的中国骰子游戏，玩家通过猜测骰子开出的点数，总和，组合等，具备多元化投注种类，以及极具吸引力的赔率，极受中国乃至亚洲玩家欢迎。": `It is an ancient Chinese dice game, where players guess the numbers, totals, combinations, etc., of the dice rolled. It features a variety of betting options and attractive odds, making it very popular among players in China and across Asia.`,
    "注意事项": `Precautions`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Some gameplay may be subject to betting limits on specific rounds in the current shoe; for details, please refer to the relevant prompts within the game.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Table limit is the minimum and maximum bet limit for the main gameplay on this table.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `When the dealer reveals the results, occasional situations may arise where the system cannot recognize them. In such cases, the dealer will attempt to re-identify the system until it can recognize them correctly. (In case of malfunction, all related bets/payouts will be canceled).`,
    "如遇结算错误，按照当期视频结果重新结算。": `In case of a settlement error, the settlement will be recalculated based on the current video results.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `If a game round cannot be completed normally due to network or other malfunctions of the gaming platform, the system will cancel the entire round of bets and refund the bet principal to the players.`
  },
  "SeDie": {
    "色碟": `Xóc đĩa`,
    "游戏规则": `Game Rules`,
    "荷官将4颗红白双色的钮扣放入碟中，盖上碗后上下摇碟；完成摇碟动作后静置于画面中供会员下注。下注时间结束将碗打开，依开出结果派彩。": `The dealer places four dual-colored (red and white) buttons into a dish and covers it with a bowl. The bowl is then shaken up and down; after completing the shaking action, it is set on the table allowing members to place their bets. When the betting time ends, the bowl is opened, and payouts are made based on the results shown.`,
    "玩法说明": `Game Introductions`,
    "大：开出(4个红色)、(3个红色，1个白色钮扣)属「大」。二红二白视为和局，退回玩家下注筹码": `Big: If it shows (4 red), (3 red, 1 white button), it is considered 'Big'. A combination of two red and two white is considered a draw, and the player's bet is returned;`,
    "小：开出(4个白色)、(3个白色，1个红色钮扣)属「小」。二红二白视为平手，退回玩家下注筹码": `Small: If it shows (4 white), (3 white, 1 red button), it is considered 'Small'. A combination of two red and two white is considered a tie, and the player's bet is returned;`,
    "单：开出(3个红色，1个白色钮扣)、(3个白色，1个红色钮扣)属「单」；": `Single: If it shows (3 red, 1 white button), (3 white, 1 red button), it is considered 'Single';`,
    "双：开出(4个红色钮扣)、(4个白色钮扣)、(2个白色、2个红色钮扣)属「双」；": `Double: If it shows (4 red buttons), (4 white buttons), (2 white, 2 red buttons), it is considered 'Double';`,
    "4（4红）：开出4个红色钮扣。": `4 (4 red): Shows 4 red buttons.`,
    "0（4白)：开出4个白色钮扣。": `0 (4 white): Shows 4 white buttons.`,
    "3(3红1白)：开出3个红色，1个白色钮扣。": `3 (3 red,1 white): Shows 3 red buttons, 1 white button.`,
    "1(3白1红)：开出3个白色，1个红色钮扣。": `1 (3 white,1 red): Shows 3 white buttons, 1 red button.`,
    "赔率表": `Odds Table`,
    "玩法赔率": `Gameplay Odds`,
    "下注组合": `Betting Combination`,
    "赔率": `Odds`,
    "大": `Big`,
    "小": `Small`,
    "单": `Odd`,
    "双": `Even`,
    "4(4红)": `4(4Red)`,
    "0(4白)": `0(4white)`,
    "3(3红1白)": `3(3Red1White)`,
    "1(3白1红)": `1(3White1Red)`,
    "特别说明": `Special Instruction`,
    "本游戏进行方式为最接近实境赌场之设置，若有发生特殊情形将依本公司公告之办法处理。": `The game is conducted in closest to a real casino setting. In case of any special circumstances, it will be handled according to our company notice.`,
    "荷官在游戏进行中若不慎让碗、碟或钮扣掉落，将物件放回定位后，重新进行摇碟。": `If the dealer accidentally drops a bowl, plate or button during the game, they will place the object back in position and reshake again.`,
    "若开出结果时有钮扣重叠无法判定结果时，荷官将用玻璃棒分开重叠的钮扣。": `If there is an overlap of buttons making it impossible to determine the result , the dealer will separate the overlapping buttons with a glass rod.`,
    "荷官若没有摇碟就开碟，或在下注时间尚未结束前即开碟，该局将判定为无效，并退还所有下注本金。": `If the dealer open the bowl without shaking them first or before the betting time is over, the game is invalid and all bets will be refunded.`,
    "荷官摇碟后开碟过程:如出现【开碟中的碗触碰到钮扣】，最终以开碟后钮扣倒下的颜色为最终结果。": `When the dealer shake and open the bowl, if 【bowl touches the button】 the final result will be determined by the color of the buttons that fall down after the bowl is opened.`,
    "注意事项": `Precautions`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Table limit is the minimum and maximum bet limit for the main gameplay on this table.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `When the dealer reveals the results, occasional situations may arise where the system cannot recognize them. In such cases, the dealer will attempt to re-identify the system until it can recognize them correctly. (In case of malfunction, all related bets/payouts will be canceled).`,
    "如遇结算错误，按照当期视频结果重新结算。": `In case of a settlement error, the settlement will be recalculated based on the current video results.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `If a game round cannot be completed normally due to network or other malfunctions of the gaming platform, the system will cancel the entire round of bets and refund the bet principal to the players.`
  },

  // 安达巴哈
'AndarBahar': {
  '安达巴哈': `Andar Bahar`,
  '游戏规则': `Game Rules`,
  '安达巴哈是一款非常简单的纸牌游戏，该游戏使用1副牌（去大小王）；': 'Andar Bahar is a very simple card game that uses 1 deck of cards (excl 2 Joker cards);',
  '荷官在小丑牌位置上发一张牌，牌面朝上，然后继续在A（安达）和B（巴哈）点上发牌，牌面朝上；': 'The dealer deals a card on the Joker card position, face up, and then continues to deal cards to A (Andar) and B (Bahar) piles, face up;',
  '出现一张牌和小丑牌的面值相同时，游戏结束。': 'The game ends when a card that matches the Joker card appears.',
  '玩法判定': ' Play Determination',
  '安达或巴哈哪家先发出和小丑牌面值相同时，哪家获胜。': 'Andar and Bahar, the pile where a card matches the Joker card first is the winner.',
  '赔率表': 'Table Odds',
  '玩法赔率': 'Play Odds',
  '投注项目': 'Betting Area	',
  '赔率': 'Odds',
  '安达': 'Andar',
  '巴哈': 'Bahar',
  '游戏特点': 'Game Features',
  '安达巴哈是印度最受欢迎的纸牌游戏之一，它的流行在于它的简单性，您只有两种玩法选择，投注简单快速。': 'Andar Bahar is one of the most popular card games in India, its popularity is due to the simplicity with only 2 plays, easy and high-speed.',
  '注意事项': 'Attention',
  '桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。': 'Table limits are the minimum and maximum limits of plays on the table.',
  '荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。': 'When the dealer reveals the results, occasional situations may arise where the system cannot recognize them. In such cases, the dealer will attempt to re-identify the system until it can recognize them correctly. (In case of malfunction, all related bets/payouts will be canceled).',
  '如遇结算错误，按照当期视频结果重新结算。': 'In the event of settlement errors, the resettlement will be made according to the video results.',
  '如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。': 'If the game can not be finished normally due to network failures, the system will cancel all the bets and return the stake.',
  },

    // 21点
    blackJack: {
      '21点': 'BlackJack',
      '游戏简述': 'Introduction',
      '21点又名黑杰克（Blackjack），起源于法国，已流传到世界各地，有着悠久的历史。该游戏使用除大小王之外的52张牌，游戏者的目标是使手中的牌的点数之和不超过21点且尽量大，然后和荷官比大小。': '21 or Blackjack is of French origin and has spread worldwide. Blackjack uses a standard deck of 52 cards excluding Joker. The Player is target is to get a total hand point closer to 21 points and to compete with the Banker.',
      '玩法规则': 'Game Rules',

      '游戏规则': 'Game Rules',
      '7个主玩家坐在赌桌上（可有无限数量的旁注玩家）。21点的目标是让您的牌比庄家的牌更接近21点，但不能超过21点。在21点中，A可以算作1，也可以算作11，花牌（J、Q、K）算10，数字牌按照面值算。Blackjack比21点大。': '7 main players allowed at one table (unlimited number of Bystander may join); The goal of the game is to have a total hand point closer to 21; "A" count as 1 or 11, Suit (J, Q, K) count as 10, digit based count as face value;',
      '如果您的头两张牌是一张A和一张点数为10的牌，则您获得了Blackjack，此时如果庄家不是Blackjack,您将赢得自己赌注的1.5倍。（如您的赌注是10，则您会收到25）。': 'If the first 2 cards are one "A" and one card worth 10 points, it is "Blackjack". If the player is "Blackjack" but the Banker is not at the first 2 cards, the player wins X1.5 bet (Example: if the player bet 10, the payout will be 25).',
      '玩法判定': '玩法判定',
      '如果您的牌的总点数比庄家的更接近21点，您将赢得您的赌注数额（如果您的赌注是10，您则收到20）。': 'lf the player total hand point is closer to 21 than the Banker,the player will win the bet amount (Example:if the player bet 10,the payout will be 20).',
      '如果您的牌的总点数超过21点，您“爆牌”并失去赌注。': 'lf the total hand point is >21 points,it will be "Bust"and the bet is lost.',
      '如果您和庄家的总点数相同（17点及以上），则双方都不算赢，您的赌注也会退回，算作平手。': 'lf the player and Banker have the same total hand point ≥ 17 points,no one wins and the bet is refunded.This is called Tie.',
      '庄家拿牌到16点，在点数达到或超过17后才能停牌（包括软17）。': 'The Banker gets an additional card until 16 points and stands at ≥ 17 points (including soft 17)',
      '游戏流程': 'Process',
      '投注主赌注后，可以投注边注玩法（完美对子、21+3）。': 'The player may place a side bet (Perfect Pair,21+3)after main bet has been placed and confirmed.',
      '投注轮结束后，使用要牌、停牌、加倍、分牌和保险（如果庄家的第一张牌是A）按钮来参与游戏。': 'Hit,Stand,Double,Split &Insurance (if Banker first card is an "A")buttons will be available after the first two cards are dealt.',
      '如果您没有选择操作，您将会自动停牌。如果您选择加倍，则会从余额中拿出与主赌注相等的金额并将您的主赌注翻倍。': 'Auto Stop if no button is selected;If select Double,the same amount as the main bet will be deducted from account balance &placed as a bet,doubling the main bet.',
  
      '要牌': 'Hit',
      '选择“要牌”继续游戏并再发一张牌。': 'Select Hit to proceed with the game and get an additional card.',
      '停牌': 'Stand',
      '选择“停牌”则该手牌的当前回合结束。': 'Selecting Stand will end the current round of the hand.',
      '分牌': 'Split',
      '如果您的前两张牌点数相同（注：10、J、Q、K均为10点），您可将其分成两手独立的牌，并投放与原赌注相同的第二份赌注。然后您可为每手牌分别要牌。分牌后不能再次分牌。': 'If the points of the player first 2 cards are the same (10,J,Q,K are all 10),the player can split them into 2 separate hands and place a 2nd bet equal to the original main bet.The player can then ask for separate cards for each hand.',
      '对于分出的每手牌，您都可以要任意数目的牌；但如果将两张A分牌，您只能为每张A再拿一张牌。如果您在分牌后得到一张A和一张点数为十的牌，那么这样的牌只能算作21点，而不算作Blackjack。这种情况下的赔付是1：1,而不是1：1.5。': 'Once the cards are dealt,the cards cannot be Split again.Hit card for Split hand;If the Player gets one "A"and one card worth 10 points after Split,it will be considered 21 points and not Blackjack.Odds will be 1:1,not 1:1.5.Only Hit 1 card for each A in hand.',
      '注：分牌后不可以投注完美对子玩法。': 'Note:Bets on Perfect Pair are not allowed after Split.',
      '加倍': 'Double',
      '在获发前两张牌后，如果您认为靠第三张牌可以赢过庄家的牌，则您可以将赌注加倍。赌注将变成原赌注的两倍（差额从您的余额中扣除），并且您将再获发一张牌。': 'lf the player first two cards are not a Blackjack,the player may Double and a card will be dealt.After Split of 2 cards,the player may double by selecting Double and a card will be dealt to each split hand.When the player selected Double,the Bet becomes X2 from the initial main bet (deducted from account balance).',
      '您可以在分牌后加倍。': 'May Double after Split.',
      '如果玩家加倍，庄家的第一张牌点数是A,且庄家获得Blackjack，则您将输掉两份赌注（初始赌注和加倍赌注）。': 'If the player selects Double and Banker first card is "A"&Blackjack,the player will lose both the initial main bet and bet placed with Double.',
      '如果庄家第一张牌点数是10，且庄家获得Blackjack，则加倍赌注返还给您。': 'If the Banker first card is 10 points &Blackjack,the "double" bet will be refunded.',
      '保险': 'Insurance',
      '如果庄家的初始牌为A，您将可以投注保险，以防庄家获得Blackjack。保险投注金额为底注的一半。': 'If Banker first card is "A",the player may buy Insurance.If the player buys Insurance,an additional bet of half of the initial main bet will be placed on the table.',
      '如果庄家的确有Blackjack，您将按1：2获得保险赔付。': 'If Banker is Blackjack,the player may get Insurance pay 1:2.',
      '如果庄家没拿到Blackjack，无论您最终输或者赢，您将输掉自己的保险投注。': 'If Banker is not Blackjack,whether the player lose or win,the Insurance bet will be lost.',
      '10牌自动胜': '10 cards auto win',
      '理论上，您有可能要10张牌但仍未爆牌(一副手牌）。这种情况下，您的手牌将自动获胜，但庄家拿到Blackjack时除外。': 'If the player Hit 10 cards with no Bust,the player will auto-win,unless Banker gets Blackjack.',
      '多座位功能': 'Multi Seat',
      '我们提供单座模式桌台和双座模式桌台。': 'Solo Seat and Double Seat Blackjack tables are available.',
      '在单座模式桌台内，每个玩家最多可以使用一个座位。': 'Every player may use max 1 seat on a Solo Seat table.',
      '在双座模式内，玩家可以占据最多2个座位，并在每个座位上分别投注。': 'Every player may use max 2 seats on a Double Seat table and bet separately.',
      '如果玩家在赌桌上没有已确认的赌注，玩家可以在两局之间或单独游戏局中更换座位。': 'If the player has no confirmed bet,the player may change seats between 2 games or within the game.',
      '如果玩家在赌桌上有已确认的赌注，玩家仅可以占据和离开没有投注的座位。': 'If the player has confirmed bet,only the seat without bet may leave.',
      '旁注功能（座位后投注）': 'Bystander (Bet after seat)',
      '本游戏提供旁注功能。这样您可以参与游戏，只需跟随主玩家的操作，对他们的手牌下注。': 'Game provides Bystander,Bystander may join the game by following another player and bet on their hand.',
      '只有被主玩家占据的位置上才有旁注选项。您可以同时成为多个位置上的旁注玩家。作为主玩家参与游戏时，您也可以在除自己位置外的所有其他位置上作为旁注玩家投注。': 'A seated main player may have multiple Bystander.You may become a Bystander of multiple seated main players.',
      '作为旁注玩家，您会自动跟随主玩家的操作，除非他们选择分牌或加倍，而您没有足够的资金这样做。这种情况下，如果主玩家加倍，您会拿牌并停牌；如果主玩家分牌，则按右主手牌操作。': 'Seated players may join and bet as Bystander on other seated player hand.The Bystander will auto-follow the main player,unless they choose to Split or Double and you do not have enough funds,you will Get and Stop if the main player Double;Operate right main hand if main player Split.',
      '如果您对一个玩家的手牌进行旁注，但该玩家在投注轮决定不下注，您的赌注将返还到您的账户。': 'If Bystander joins a main player hand,but the main player does not place a bet,the Bystander bet will be refunded.',
      '热门玩家图标帮助您了解当前哪位玩家连胜最高。': 'The player who has the highest win in a row is indicated with a Hot Player Icon.',
      '数字表示该玩家连胜的局数。如果该玩家输了一局，他将失去热门玩家图标。': 'The Digit shown in the Hot Player icon indicates the number of wins in a row.If the player lose 1 game,the Hot Player Icon will be removed.',
      '发牌方式': 'Draw style',
  
      '我们采用欧式发牌方式。': 'The Blackjack game uses European Draw.',
      '庄家在所有玩家都做出决定后获得第二张牌。': 'Banker Hit second card after all player actions are done.',
      '发牌方式会影响保险和操作回合的顺序：': 'Draw style effect Insurance and game sequence:',
      '对于欧式发牌，当您获得第二张牌且庄家的第一张牌是A时，将提供保险选项。不过您会在保险回合后直接进入操作回合，所有玩家都操作完毕后会将第二张牌发给庄家。': 'lf the Banker first card is "A",after the player get the second card,the Insurance option will be provided.',
      '关于21点游戏桌台，我们存在以下2种:': 'There are 2 types of Blackjack tables available.::',
      '1.使用8副牌透明牌靴的桌台，其标志为': '1.Blackjack table using 8-deck card shufllers are marked as. ',
      '2.使用6副牌洗牌机的桌台，其标志为': '2.Blackjack table using 6-deck card shufflers are marked as. ',
  
      '边注': 'Side',
      '这些是主游戏之外的玩法，必须投注主赌注后才可投注这些玩法。': 'Place a main bet and enable bets on Side bets.',
      '21+3': '21+3',
      '玩家可以对发给玩家的前两张牌连同发给庄家的第一张牌下注。': 'Player may bet on player first 2 cards and Banker first card.',
      '同花三条：所有三张牌的点数和花色都相同': 'Suited Trips:3 cards same points and suit.',
      '同花顺：所有三张牌花色相同、点数依次排列': 'Straight Flush:3 cards same suit points in order.',
      '三条：所有三张牌大小相同': 'Three of A Kind:3 cards same rank.',
      '顺子：所有三张牌花色不同但点数依次排列': 'Straight: 3 cards non-suited, points in order',
      '同花：所有三张牌花色相同': 'Flush: 3 cards same suit',
  
      '同花三条': 'Suited Trips',
      '(完全相同的三张牌)赔率 1：100': 'odds 1:100',
      '同花顺': 'Straight Flush',
      '（同花顺子,三张同花顺序）赔率 1：40': 'odds 1:40',
      '三条': 'Three of A Kind',
      '（三张相同牌值的非同花牌）赔率 1：30': 'odds 1:30',
      '顺子': 'Straight',
      '（非同顺子,三张不同花顺序,包含Q、K、A牌型）赔率 1：10': 'odds 1:10',
      '同花': 'Flush',
      '（三张同一花色的牌）赔率 1：5': 'odds 1:5',
  
      '以玩家首2张牌算': 'Compute player first 2 cards.',
      '完美对子': 'Perfect Pair',
      '（闲家一对同色同花同点值）赔率 1：25': '1pair of same suit and rank Odds 1:25.',
      '同色对子': 'Color Pair',
      '（闲家一对同色同点值不同花）赔率 1：12': '1 pair of same color,rank but non-suited Odds 1:12.',
      '混合对子': 'Mix Pair',
      '（闲家一对不同色不同花同点值）赔率 1：6': '1 pair of same rank but different color and suit Odds 1:6.',
  
      '赔率表': 'Odds Table',
      '玩法赔率': 'Gameplay Odds',
      '主游戏': 'Main Game',
      '赔率': 'Odds',
      '投注项目': 'Betting Option',
      '主赌注': 'Main Bet	',
      'Blackjack': 'Blackjack',
      '21+3边注玩法': '21+3 Side',
      '完美对子边注玩法': 'Perfect Pair Side',
  
      '注意事项': 'Precautions',
      '部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。': 'The game has minimum and maximum bet limits.View more info in game.',
      '所有入座玩家必须先下注某个位置的底注才能下注该位置的完美对子和21+3玩法。': 'All Seat players must bet on Ante to be allowed to bet on Perfect Pair and 21+3.',
      '如果赌桌上的每位玩家均爆牌（点数高于21点），那么这局游戏结束；庄家不需要拿牌。如果赌桌上的每位玩家均爆牌（点数高于21点），但庄家底牌为A,庄家需要拿1牌，以判断庄家是否为BJ。': 'If all players Bust (> 21points), the game will end; Banker does not Hit if all players Bust (> 21points), except when Banker first card is A, then the Banker will Hit 1 card to determine if hand is a Blackjack.',
      '桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。': 'Table limits are the minimum and maximum limits of plays on the table.',
      '荷官发牌时，偶有系统无法判读状况时，此时荷官将重新扫描，直到系统能完整判读（如遇故障将取消所有相关注单/派彩）。': 'If a system identification error occurs during card reveal, the dealer will reidentify using the operating system until successful (All bets/payout will be cancelled if fail to reveal card).',
      '如遇结算错误，按照当期视频结果重新结算。': 'The game will resettle based on current video results if a settlement error occurs.',
      '如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。': 'If platform disconnection occurs, the system will cancel whole rounds and refund bets.',
      '如果您从游戏中断开，任何已确定赌注将保持有效，并在您不在场的时候结算。重新连接后，您可以在“投注记录”中查看结果。': 'If the player is disconnected, any confirmed bet remains valid and will be settled in your absence. The player can view the result in "Bet Record" after reconnection.'
    },

  "Roulette": {
    "轮盘": `Roulette`,
    "游戏规则": `Game Rules`,
    "荷官转动轮盘，并抛出象牙制滚球到轮盘外侧；": `The dealer spins the roulette wheel and throws an ivory ball onto the outer track of the wheel;`,
    "当滚球在轮盘内转动多周后减速并落入下方格子；": `As the ball slows down after several spin, it falls into one of the pockets;`,
    "该格子所对应的数字，作为该局结果；": `The number corresponding to that pocket determines the result of the round;`,
    "若滚球在轮盘转动小于或等于3圈，需要重新打球。": `If the ball spins less than or equal to three circles, a re-spin is required.`,
    "玩法判定": `Game Play Determination`,
    "美式注盘": `American Betting Layout`,
    "美式下注盘也叫经典盘，包含了直注以及各种组合下注。玩家可在游戏中切换美式和法式下注盘。": `The American betting layout, also known as the classic layout, includes straight bets and various combinations of bets. Players can switch between American and French betting layouts during the game.`,
    "美式-直注": `American - Straight Bet`,
    "即直接点击数字方块，对0-36的数字直接下注。": `Directly click on the number square to bet on numbers 0-36.`,
    "例如，下注11点，只有当结果是11时才会胜出。": `For example, betting on 11, you only win if the result is 11.`,
    "美式-分注": `American - Split Bet`,
    "即在线上下注，当结果为线两边的数字时胜出。": `Place bets online; you win if the result is on the numbers on either side of the line.`,
    "例如，下注3和6之间的线，或者2和3之间的线。": `Example, bet on the line between 3 and 6, or the line between 2 and 3.`,
    "美式-三数": `American - Trio`,
    "三数为同时投注包含0在内的三个数字。": `Bet on three numbers at the same time, including 0 in the group.`,
    "例如，0，1，2为一组。0，2，3为一组。": `Example, 0, 1, 2 as one group. 0, 2, 3 as another group.`,
    "美式-四号": `American - First Four`,
    "同时对0，1，2，3四个数字进行下注。": `Place bets simultaneously on the numbers 0, 1, 2, and 3.`,
    "美式-角注": `American - Corner`,
    "对4个数字方框交叉处点击。": `Click on the intersection of the four number squares.`,
    "例如：1，2，4，5为一注。2，3，5，6为一注。": `Example: 1, 2, 4, 5 as one bet. 2, 3, 5, 6 as another bet.`,
    "美式-街注": `American - Street Bet`,
    "对3个相连的数字投注。": `Bet on three consecutive numbers.`,
    "例如：1，2，3为一组。7，8，9为一组。": `Example: 1, 2, 3 as one group. 7, 8, 9 as another group.`,
    "美式-线注": `American - Line Bet`,
    "类似街注，区别在于对2个街注相交的位置下注，同时下注6个数字。": `Similar to a street bet, but the difference lies in betting on the intersection of two street bets, covering six numbers.`,
    "例如：1，2，3，4，5，6一注。4，5，6，7，8，9一注。": `Example: 1, 2, 3, 4, 5, 6 as one bet. 4, 5, 6, 7, 8, 9 as another bet.`,
    "美式-列注": `American - Column Bet`,
    "每列12个号码，对“第X列”处下注。": `Bet on a column of 12 numbers.`,
    "例如：第一列包括1，4，7，10，13，16，19，22，25，28，31，34，共12个数字。": `Example: The first column includes 1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, totaling 12 numbers.`,
    "美式-打注": `American - Dozen Bet`,
    "将筹码下到“第X打”的位置。": `Place chips on the "Dozen" section.`,
    "例如：第一打包括1-12。第二打为13-24。第三打为25-36。": `Example: The first dozen includes numbers 1-12. The second dozen 13-24. The third dozen 25-36.`,
    "美式-红黑": `American - Red/Black`,
    "对桌台上标注红，黑的位置下注。若开0则通杀，红/黑均输。": `Bet on the red or black spots marked on the table. If 0 is rolled, it's a complete loss; both red and black lose.`,
    "例如：所有为红色格子的数字或所有黑色格子的数字。": `Example: Bet on all numbers in red squares or all numbers in black squares.`,
    "美式-单双": `American - Odd/Even`,
    "对桌台上标注单，双的位置下注。若开0则通杀，单/双均输。": `Bet on the spots marked even or odd on the table. If 0 is rolled, it's a complete loss; both even and odd lose.`,
    "例如：单数包括1，3，5……35的单数。双则表示2，4，6……36的双数。": `Example: Odd numbers include 1, 3, 5...35. Even numbers represent 2, 4, 6...36.`,
    "美式-大小": `American - Big/Small`,
    "对桌台上标注大，小的位置下注。若开0则通杀，大/小均输。": `Small (as from number 1 to 18); Big (as from 19-36); If show zero mean all lost.`,
    "例如：小（1-18）。大（19-36）。": `Example: Small (1-18). Big (19-36).`,
    "法式注盘": `French Betting Layout`,
    "也叫欧式注盘，可视为对美式注盘的衍生。将美式注盘的一部分打包组合后一起下注的玩法。": `Also known as the European betting layout, it can be considered a derivative of the American layout. It's a betting method where parts of the American layout are bundled together for betting.`,
    "法式-相邻投注：": `French - Neighbors Bet：`,
    "点击法式轮盘的格子，同时对点击格子左邻和右邻两个号码（一共5号码），按顺序各下一注。": `Click on the grid of the French roulette wheel, betting on the number you click and its immediate neighbors on both sides (a total of 5 numbers).`,
    "例如：选中5，则投注号码为23，10，5，24，16。": `Example: If you choose 5, then bet on the numbers 23, 10, 5, 24, 16.`,
    "法式-轮上零旁：": `French - Orphelins: `,
    "点击轮上零旁区域。": `Click in the area next to zero on the wheel.`,
    "对0和3；12和15；32和35；26；各下一注。": `Bet on combinations 0 and 3; 12 and 15; 32 and 35; 26; one bet each.`,
    "法式-零旁注上角：": `French - Voisins du Zero:`,
    "点击零旁注上角区域，对以下区域和组合进行下注。": `Click in the upper corner of the zero neighbors section, betting on the following areas and combinations.`,
    "4和7；12和15；18和21；19和22；32和35；5个组合各一注；": `4 and 7; 12 and 15; 18 and 21; 19 and 22; 32 and 35; five combinations, one bet each;`,
    "0，2，3的组合，下两注；": `Combinations 0, 2, 3; place two bets;`,
    "25，26，28，29的组合，下两注。": `Combinations 25, 26, 28, 29; place two bets.`,
    "法式-轮上孤注：": `French - Tier du Cylindre:`,
    "点击轮盘孤注，对以下区域组合进行下注。": `Click on the solitary bet section of the roulette wheel, betting on the following area combinations.`,
    "即轮盘枱上的1号，6号，9号；14号，17号；17号，20号；31号，和34号几种组合各投1注。": `Bets are placed on numbers 1, 6, 9; 14, 17; 20, 31, and 34, one bet each.`,
    "法式-轮盘下角注：": `French - Bottom Corner Bet: `,
    "法式-轮盘下角注。": `French - Bottom Corner Bet.`,
    "5和8；10和11；13和16；23和24；27和30；33和36这六个玩法下1注。": `5 and 8; 10 and 11; 13 and 16; 23 and 24; 27 and 30; 33 and 36; one bet each on these six plays.`,
    "小球落入的格子对应信息包含数字，红黑。数字分大小，单双；": `The slot where the ball lands includes information such as the number, color (red or black). The numbers are categorized by high/low and even/odd.`,
    "同时根据数字在下注盘的位置，分为“打”和“列”；": `Based on the number's position on the betting layout, they are classified into 'dozens' and 'columns';`,
    "也有根据数字所在下注盘位置，分为不同组的玩法。": `There are also bets based on the number's position within the betting layout, which are divided into different groups of plays.`,
    "赔率表": `Odds Table`,
    "由于法式下注盘只是对美式下注盘的玩法打包，其赔率对应到美式下注盘，因此赔率以美式为主。": `Since the French betting layout is merely a packaged version of the American betting layout, its odds correspond to the American layout, and therefore, the odds are primarily based on the American style.`,
    "投注项目": `Bets Options`,
    "赔率": `Odds`,
    "直注": `Straight`,
    "分注": `Split`,
    "街注": `Street`,
    "三数": `Trio`,
    "角注": `Corner`,
    "四号": `First Four`,
    "线注": `Line`,
    "列注": `Column`,
    "打注": `Dozen`,
    "红/黑": `Red/Black`,
    "单/双": `Odd/Even`,
    "大/小": `Big/Small`,
    "游戏特点": `Game Features`,
    "欧美常见的竞猜游戏，一度风靡于皇族和贵族之间，现代社会才步入平民娱乐。被誉为最高贵的游戏。以下注玩法多维度著称，单押直注追求高赔率，或多点投入追求中奖率，休闲与刺激兼顾。": `Roulette is a common betting game in Europe and America, once popular among royalty and nobility, and has only recently become entertainment for the masses in modern society. It is known as the most noble of games. The following betting methods are renowned for their multidimensional appeal, where single straight bets are pursued for high odds and multiple bets are made to increase the chances of winning, balancing leisure and excitement.`,
    "注意事项": `Precautions`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Some gameplay may involve betting limits on specific rounds in the current shoe; please see in-game prompts for details.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Table limit is the minimum and maximum bet limit for the main gameplay on this table..`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `When the dealer reveals the results, occasional situations may arise where the system cannot recognize them. In such cases, the dealer will attempt to re-identify the system until it can recognize them correctly. (In case of malfunction, all related bets/payouts will be canceled).`,
    "如遇结算错误，按照当期视频结果重新结算。": `In case of a settlement error, the settlement will be recalculated based on the current video results.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `If a game round cannot be completed normally due to network or other malfunctions of the gaming platform, the system will cancel the entire round of bets and refund the bet principal to the players.`
  },
  "Fantan": {
    "番摊": `Fan Tan`,
    "游戏规则": `Game Rules`,
    "游戏使用钮扣300颗，新局开始后，荷官用铁碗从钮扣堆中取出不知数的钮扣，开始下注。": `The game uses 300 buttons. After the start of a new round, the dealer uses an iron bowl to scoop an unknown number of buttons from the pile, and then betting begins.`,
    "下注结束后，荷官即翻开铁碗收皮，用一个棒子将钮扣每4个为一列排好，直到剩下4或少于4个为止。": `After betting ends, the dealer reveals the contents of the bowl and uses a stick to arrange the buttons into rows of four until fewer than four buttons remain.`,
    "最后剩下的钮扣数即为本局开奖结果，画面会显示玩家的输赢情况。": `The number of buttons left at the end determines the result of the round, and the screen will display whether players have won or lost.`,
    "玩法判定": `Game Play Determination`,
    "单：1、3为单；": `Odd: 1, 3 are odd numbers;`,
    "双：2、4为双。": `Even: 2, 4 are even numbers.`,
    "番：投注单一号码，开出为赢，其余为输。如：1番，开1为赢，2、3、4为输；": `Fan: Bet on a single number, win if that number comes up, lose if any other number comes up. Example: Bet on 1, win if 1 is drawn, lose if 2, 3, or 4 are drawn;`,
    "念：投注两个号码，一个为赢，一个为和，另外两个为输 如：1念2，开1为赢，开2为和，3、4为输；": `Nim: Bet on two numbers, one to win, one to draw, the other two lose. Example: Bet on 1-2, win if 1 is drawn, draw if 2 is drawn, lose if 3 or 4 are drawn;`,
    "角：投注两个号码，开出为赢，其余为输。如：1.2角，开1、2为赢，3、4为输；": `Kwok: Bet on two numbers, win if either is drawn, lose if the other two are drawn. Example: Bet on 1-2, win if 1 or 2 are drawn, lose if 3 or 4 are drawn;`,
    "通：投注两个号码，开出为赢，其余两个号码一输一和。如：12三通，则开1、2为赢，3为输，4为和；": `Nga: Bet on two numbers, if either wins, the remaining two numbers result in one loss and one draw. Example: Bet on 1-2 in "three Tong", win if 1 or 2 is drawn, 3 loses, 4 draws;`,
    "三门：投注三个号码，如果开出的结果是三个号码之一，则中奖；其他则输。如：三门123，开1、2、3为赢，4为输；": `SsH: Bet on three numbers, win if any of the bet numbers are drawn, lose if any other number is drawn. Example: Bet on 1-2-3, win if 1, 2, or 3 is drawn, lose if 4 is drawn;`,
    "赔率表": `Odds Table`,
    "玩法赔率": `Gameplay Odds`,
    "投注项目": `Bets Options`,
    "赔率": `Odds`,
    "单": `Odd`,
    "双": `Even`,
    "番": `Fan`,
    "念": `Nim`,
    "角": `Kwok`,
    "通": `Nga`,
    "三门": `SsH`,
    "游戏特点": `Game Features`,
    "番摊是中国古老的做庄游戏，流行于两广一带，当年广摊只有「番」一种玩法，故名番摊，时至今日，番摊玩法已改变不少，除了番之外还多了念、角、通、门、单双这几种玩法。": `FanTan is an ancient Chinese banking game, popular in the Guangdong and Guangxi regions. Originally, Fan-Tan only had the 'Fan' play method, which is how it got its name. Today, the methods of playing Fan-Tan have diversified significantly, including additional betting options like 'Nim', 'Kwok', 'Nga', 'SsH', and 'Odd/Even.`,
    "注意事项": `Precautions`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Some gameplay options may have betting restrictions for specific rounds within the shoe; please refer to in-game prompts for details.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Table limit is the minimum and maximum bet limit for the main gameplay on this table.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单/派彩）。": `When the dealer reveals the results, occasional situations may arise where the system cannot recognize them. In such cases, the dealer will attempt to re-identify the system until it can recognize them correctly. (In case of malfunction, all related bets/payouts will be canceled).`,
    "如遇结算错误，按照当期视频结果重新结算。": `In the event of a settlement error, recalculations will be based on the current video results.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `If a game round cannot be completed normally due to issues with the gaming platform's network or other faults, the system will cancel all bets for that round and refund the bet amounts.`
  },
  "FriedGoldenFlower": {
    "炸金花": `Win 3 cards`,
    "游戏规则": `Game Rules`,
    "使用一副牌游戏（去大小王）进行游戏；": `The games is using a standard deck of cards (excluding Jokers) `,
    "先比较牌型大小，若牌型一致再按从大到小的顺序依次比较牌面大小，决定胜负。": `First, compare the size of the card hands. If the hands are of the same type, compare the cards from highest to lowest  to determine the winner.`,
    "玩法判定": `Game Play Determination`,
    "牌型": `Hand Types`,
    "豹子杀手：": `2-3-5 Killer:`,
    "三张非同花的2，3，5；": `Three non-suited cards of 2, 3, and 5;`,
    "豹子：": `Three of a kind:`,
    "三张点数相同的牌；": `Three cards with the same number of points;`,
    "同花顺：": `Straight Flush:`,
    "花色相同的顺子牌；": `Three consecutive cards of the same suit;`,
    "同花：": `Flush:`,
    "花色相同的任意三张牌；": ` Any three cards of the same suit;`,
    "顺子：": `Straight:`,
    "三张连续的牌；": `Three consecutive cards;`,
    "对子：": `Pair: `,
    "两张点数相同的牌；": `Two cards of the same rank;`,
    "散牌：": `High Card:`,
    "无法组成任何类型的牌；": `A hand that does not form any of the above types;`,
    "牌型大小": `Hand Rankings`,
    ">豹子＞同花顺＞同花＞顺子＞对子＞散牌；": `Three of a kind > Straight Flush > Flush > Straight > Pair > High Card;`,
    "豹子杀手＞豹子；": `2-3-5 Killer > Three of a kind;`,
    "当豹子牌和豹子杀手牌同时存在时，豹子杀手牌比豹子牌大；": `When both a Three of a kind and 2-3-5 Killer (a special hand in the game) are present, the 2-3-5 Killer ranks higher than the Three of a kind;`,
    "散牌＞豹子杀手；": `High Card > 2-3-5 Killer;`,
    "如不存在豹子牌，豹子杀手是最小的散牌牌型。": `If there is no Three of a kind, the 2-3-5 Killer is considered the lowest of the High Card hands.`,
    "对子大小": `Pair Rankings`,
    "如果两家都是对子牌，即比对对子点数大小，对A＞对K＞对Q＞对J＞对10＞对9＞对8＞对7＞对6＞对5＞对4＞对3＞对2；": `If both players have pairs, compare the ranks of the pairs from highest to lowest as follows: Pair of Aces > Pair of Kings > Pair of Queens > Pair of Jacks > Pair of Tens > Pair of Nines > Pair of Eights > Pair of Sevens > Pair of Sixes > Pair of Fives > Pair of Fours > Pair of Threes > Pair of Twos;`,
    "两对对子的点数相同，再比单牌点数大小。": `If the pairs are of the same rank, then compare the ranks of the remaining odd card.`,
    "豹子、同花、散牌牌型": `Three of a kind, Flush, and High Card hands`,
    "比最大那张牌的点数，如最大的那张点数相同，则比第二大牌的点数，以此类推；A>K>Q>J>10>9>8>7>6>5>4>3>2。": `Compare the highest card's rank. If the highest cards are the same, then compare the second-highest, and so on in descending order: Ace > King > Queen > Jack > 10 > 9 > 8 > 7 > 6 > 5 > 4 > 3 > 2.`,
    "同花顺，顺子": `Straight Flush and Straight:`,
    "按照顺序比点数：AKQ>KQJ>......>432>32A": `Compare the card ranks in sequence: AKQ > KQJ > ... > 432 > 32A`,
    "注意：只有在同花顺和顺子牌型中，A可作为最大或最小的牌，其余牌型中A为最大的单牌": `Note: Only in the Straight Flush and Straight hand types can the Ace act as either the highest or lowest card. In all other hand types, the Ace is considered the highest single card.`,
    "赔率表": `Odds Table`,
    "玩家赢赔率": `Player's Winning Odds`,
    "玩家输赔率": `Player's Losing Odds`,
    "胜方牌型": `Winning Hand`,
    "平倍": `Even`,
    "翻倍": `Double`,
    "超倍": `Super`,
    "单牌": `High Card`,
    "单牌以下": `High Card{value}below`,
    "对子": `Pair`,
    "顺子": `Straight`,
    "同花": `Flush`,
    "同花顺": `Straight Flush`,
    "三条": `Three of a Kind`,
    "豹子杀手": `2-3-5 Killer`,
    "游戏特点": `Game Features`,
    "炸金花，也被称为赢三张。是一种广泛流传的民间游戏。": `Win 3 cards also known as "Win Three Cards," is a widely popular folk card game. `,
    "注意事项": `Precautions`,
    "部分玩法可能会存在本靴指定局数可投注限制，详情请查看游戏内相关提示。": `Some betting options may have limitations on the number of rounds per shoe that can be bet on; please refer to the game-specific guidelines for details.`,
    "桌台限红是本游戏桌台主要玩法的最小、最大玩法限红。": `Table limit is the minimum and maximum bet limit for the main gameplay on this table.`,
    "荷官开牌时，偶有系统无法识别状况，此时荷官将操作系统尝试重新识别，直到系统能正确识别（如遇故障因素将取消所有相关注单": `When the dealer reveals the results, occasional situations may arise where the system cannot recognize them. In such cases, the dealer will attempt to re-identify the system until it can recognize them correctly. (In case of malfunction, all related bets/payouts will be canceled).`,
    "如遇结算错误，按照当期视频结果重新结算。": `In the event of a settlement error, the results will be recalculated based on the current video evidence.`,
    "如游戏过程中因游戏平台网络等故障造成未能正常完成牌局，系统将取消整局玩家下注并退回下注本金。": `If a game cannot be completed normally due to network failures or other issues with the gaming platform, the system will cancel all bets for the round and refund the stakes.`
  }
};

export default en;